import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import portfoliologo from "../Images/portfoliologo.png";
import errorglow from "../Images/errorglow.svg";
import timerglow from "../Images/timerglow.svg";

const Accesserrormodal = ({ closeModal, openModal, modalType }: any) => {
  const remainingDays = sessionStorage.getItem('trialDays')!==undefined?sessionStorage.getItem('trialDays'):'';
  return (
    <Modal
      className="policyModal modal"
      centered
      show={openModal}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="justify-content-center">
        {modalType==2?<>
        <h5 className="card-title d-flex justify-content-center">
          <img src={portfoliologo} style={{ maxHeight: "30px" }} alt="Logo" />
        </h5>
        <div
          style={{ margin: "20px" }}
          className="d-flex justify-content-center"
        >
          <img src={errorglow} style={{ height: "80px", width: "80px" }} />
        </div>
        <div className="d-flex justify-content-center">
          <span
            style={{
              fontSize: "30px",
              fontFamily: "Poppins-Regular",
              fontWeight: "700",
            }}
          >
            Your trial has been expired
          </span>
        </div>
        <div className="" style={{ textAlign: "center", fontSize: "16px" }}>
          We hope you enjoyed your trial! Please note that your trial period has
          expired. To continue using{" "}
          <span style={{ fontWeight: "bold" }}>Portfolio One</span> consider
          upgrading to a full subscription. Thank you for trying out{" "}
          <span style={{ fontWeight: "bold" }}>Portfolio One.</span> We look
          forward to serving you with our premium features!
        </div>
        </>:null}

        
        {modalType==3?<>
        <h5 className="card-title d-flex justify-content-center">
          <img src={portfoliologo} style={{ maxHeight: "30px" }} alt="Logo" />
        </h5>
        <div
          style={{ margin: "20px" }}
          className="d-flex justify-content-center"
        >
          <img src={timerglow} style={{ height: "80px", width: "80px" }} />
        </div>
        <div className="d-flex justify-content-center">
          <span
            style={{
              fontSize: "30px",
              fontFamily: "Poppins-Regular",
              fontWeight: "700",
            }}
          >
            Your trial ends in {remainingDays} days
          </span>
        </div>
        <div className="" style={{ textAlign: "center", fontSize: "16px" }}>
        We hope you've been enjoying our service! This is a friendly reminder that your trial period is coming to an end in 
        <span style={{ fontWeight: "bold" }}> {remainingDays} days. </span> 
        To continue experiencing the benefits, consider upgrading to a full subscription. Don't miss out on all the features!{" "}
        </div>
        </>:null}



        {modalType==4?<>
        <h5 className="card-title d-flex justify-content-center">
          <img src={portfoliologo} style={{ maxHeight: "30px" }} alt="Logo" />
        </h5>
        <div
          style={{ margin: "20px" }}
          className="d-flex justify-content-center"
        >
          <img src={errorglow} style={{ height: "80px", width: "80px" }} />
        </div>
        <div className="d-flex justify-content-center">
          <span
            style={{
              fontSize: "30px",
              fontFamily: "Poppins-Regular",
              fontWeight: "700",
            }}
          >
            Limit Exceeded
          </span>
        </div>
        <div className="" style={{ textAlign: "center", fontSize: "16px" }}>
        To create additional sites, please consider subscribing, as the current limit allows for the creation of up to 5 sites and 10,000 sq.feet . 
        <p>Thank you for your understanding and support!</p>
        </div>
      
        </>:null}
      </Modal.Body>
      <Modal.Footer className="mt-auto d-flex justify-content-center">
        <Button className="submit btn rounded-pill" onClick={closeModal} style={{width:'100px'}}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Accesserrormodal;
