import React,{useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Lease from './Lease';
import Dashboard from './Dashboard';
import Navigationbar from './Navigationbar';
import Sitelist from './Sitelist';
import Sidebarnav from './Sidebarnav';
import Usermanagement from './Usermanagement';
import Rolemanagement from './Rolemanagement';
import Subscription from './Subscription';
import Headcountgrowth from './Headcountgrowth';
import Orgmanagement from './Orgmanagement';
import Uploaddoc from './Uploaddoc';
import Setting from './Setting';
import Feedback from './Feedback';
import Faq from './Faq';
import '../CSS/navigation.css';
import Bulk from './Bulk';
import {Component, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useNavigate, Outlet } from 'react-router-dom';


const Home = ({onLogout}:any) => {
  const navigate = useNavigate();
  var userRoleId:any=sessionStorage.getItem('userRoleId')
  const path = window.location.pathname;
const pathSegments = path.split('/').filter(segment => segment.trim() !== ''); // Split path by '/' and remove empty segments

let lastSegment = '';

if (pathSegments.length > 0) {
  lastSegment = pathSegments[pathSegments.length - 1];
}
  const [activeSection, setActiveSection] = useState(lastSegment);
  
  const handleSectionChange = (sectionName: string) => {
    setActiveSection(sectionName);
  };
  return (
    <>
    
    <Navigationbar onLogOut={onLogout} activeSection={activeSection} onSectionChange={handleSectionChange}/>
    <div style={{ paddingTop: '80px' }}>
    <Sidebarnav activeSection={activeSection} onSectionChange={handleSectionChange}/>
    <section className="home">
    <Routes>
    <Route index element={<Sitelist />} />
    
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="addSite/bulkSites" element={<Bulk />} />
        <Route path="addSite/singleSite" element={<Lease />} />
        <Route path="siteList" element={<Sitelist />} />
        
       
        <Route path="userManagement" element={<Usermanagement />} />
        
        {userRoleId=='3'?
        <Route path="roleManagement" element={<Rolemanagement />} />:null}

        {userRoleId!=='1'?
        <Route path="subscription" element={<Subscription />} />:null}

        {userRoleId!='1'?
        <Route path="headcountgrowth" element={<Headcountgrowth />} />:null}

        {userRoleId=='1'?
        <Route path="feedback" element={<Feedback />} />:null}

        {userRoleId=='3'?
        <Route path="orgManagement" element={<Orgmanagement />} />:null} 

        <Route path='setting' element={<Setting />} />
        <Route path='uploadleasedocument' element={<Uploaddoc />} />

        <Route path='faq' element={<Faq />} />

        <Route path="*" element={<Sitelist />} />
    </Routes>
    </section>
    </div>
   
    </>
  )
}

export default Home