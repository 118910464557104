import React, { useState } from "react";
import "../CSS/Intro.css";
import portfoliologo from "../Images/portfoliologo.svg";
import { useNavigate } from "react-router-dom";
import sec1 from "../Images/sec1.svg";
import sec2 from "../Images/sec2.svg";
import sec3 from "../Images/sec3.svg";
import whiteLogo from "../Images/whiteLogo.svg";
import { Modal, Button } from "react-bootstrap";
import Privacypolicy from "./Privacypolicy";

const Introduction = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const onClickLogin = () => {
    navigate("/login");
  };

  const onClickRegister = () => {
    navigate("/register");
  };
  return (
    <div className="" style={{ background: "black", maxWidth: "100%" }}>
      <div className=" px-0" style={{}}>
        <div className="Intro">

        <span className="fixed text-white" style={{ background: "#10346B" }}>
  <div
    className="container-fluid"
    style={{ paddingTop: "20px", paddingBottom: "20px" }}
  >
    <div className="row p-0 m-0">
      <div className="col-4" style={{ display: 'flex', justifyContent: 'start' }}>
        <img
          src={whiteLogo}
          style={{ maxHeight: "30px", color: "#FFFFFF" }}
          alt="Logo"
        />
      </div>
      <div
        className="col-8"
        style={{
          display: 'flex',
          justifyContent: 'end',
          textAlign: "right",
          fontSize: "16px",
          fontFamily: "Poppins-Regular",
          color: "#636363"
        }}
      >
        <button
          className="btn btn-lg rounded-pill px-3"
          style={{
            color: "#00A978",
            fontFamily: "Poppins-Medium",
            fontSize: "16px",
            border: "1px solid #00A978",
            cursor: "pointer"
          }}
          onClick={onClickLogin}
        >
          Login
        </button>
        <button
          className="btn btn-lg rounded-pill px-3"
          style={{
            backgroundColor: "#00A978",
            color: "#FFF",
            fontFamily: "Poppins-Medium",
            fontSize: "16px",
            marginLeft: '10px',
            cursor: "pointer"
          }}
          onClick={onClickRegister}
        >
          Register
        </button>
        <button
          className="btn btn-lg rounded-pill px-3"
          style={{
            background: "#019FDF",
            color: "#FFFFFF",
            fontFamily: "Poppins-Medium",
            fontSize: "16px",
            marginLeft: '10px',
            cursor: "pointer"
          }}
        >
          <i className="fa fa-lg fa-apple mx-2"></i>Download the App Now
        </button>
      </div>
    </div>
  </div>
</span>



          <div className="scrollable-body">
            <div
              className="justify-content-center mainBody1 col"
              style={{ paddingTop: "100px" }}
            >
              <div className="col-12">
                <div style={{ lineHeight: "1.3", marginBottom: "46px",fontSize: "36px" }}>
                  <span
                    className=""
                    style={{
                      fontFamily: "Poppins-Medium",
                      color: "#FFFFFF",
                      
                    }}
                  >
                    A Concise Solution for Workforce
                  </span>
                  <br />
                  <span
                    className="g1"
                    style={{ fontFamily: "Poppins-Medium" }}
                  >
                    Real Estate Investment Decisions
                  </span>
                  <br />
                  <span
                    className=""
                    style={{
                     fontFamily: "Poppins-Medium",
                      color: "#FFFFFF",
                    }}
                  >
                    in A Hybrid World.
                  </span>
                  <br />
                </div>
                <div style={{ lineHeight: "1" }}>
                  <div
                    className=""
                    style={{
                      fontSize: "22px",
                      fontFamily: "Poppins-Medium",
                      color: "#FFFFFF",
                    }}
                  >
                    The hybrid workforce is here to stay, and senior leaders
                    are struggling to keep up.
                  </div>
                  <br />
                  <span
                    className=""
                    style={{
                      fontSize: "18px",
                      fontFamily: "Poppins-Regular",
                      color: "#FFFFFF",
                    }}
                  >
                    Portfolio One empowers Executives to make informed decision
                    on where to inverst capital for smart workforce planing in
                    the new world of employment.
                  </span>
                  <br />
                </div>
                 <button className="btn col-2 my-5" 
                 onClick={onClickRegister} style={{ background: "#00A978", color: "#FFFFFF", fontFamily: "Poppins-Medium", fontSize: "23px", padding:'14px', borderRadius:'112px' }}>Start Free Trial</button>
                 <div style={{display:'flex'}}>
                <img
                  src={sec1}
                  className="offset-1 col-10"
                  style={{ marginTop: "20px" }}
                  alt="Logo"
                />
                </div>
              </div>
            </div>

            <div
              className="col-12"
              style={{
                background: "#F9F9F9",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
             
                <div style={{ lineHeight: "1.2", marginBottom: "40px" }}>
                  <span
                    className=""
                    style={{
                      fontSize: "36px",
                      fontFamily: "Poppins-Medium",
                      color: "#10346B",
                    }}
                  >
                    Helping Senior Leaders Navigate the
                  </span>
                  <br />
                  <span
                    className="g2"
                    style={{ fontSize: "36px", fontFamily: "Poppins-Medium" }}
                  >
                    Hybrid, Remote & In-Office Workforce.
                  </span>
                  <br />
                </div>
                <div style={{ lineHeight: "1" }}>
                  <div
                    className=""
                    style={{
                      fontSize: "22px",
                      fontFamily: "Poppins-Medium",
                      color: "#2E3034",
                    }}
                  >
                    Learn more about how Portfolio One can help you to develop
                    and manage thriving workplaces like a boss.{" "}
                  </div>
                </div>
                <img
                  src={sec2}
                  className="col-10"
                  style={{ marginTop: "70px" }}
                  alt="Logo"
                />
                <div
                  className="offset-1 col-10 my-5"
                  style={{ textAlign: "left", lineHeight: "1.2" }}
                >
                  <span
                    className="g2"
                    style={{ fontSize: "36px", fontFamily: "Poppins-Medium" }}
                  >
                    With Portfolio One,
                  </span>
                  <br />
                  <span
                    className=""
                    style={{
                      fontSize: "36px",
                      fontFamily: "Poppins-Medium",
                      color: "#10346B",
                    }}
                  >
                    You Can:
                  </span>
                </div>
                <div className="card-container offset-1 col-10">
                  <div className="card py-3 px-2" style={{ border: "none" }}>
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <span
                        className="card-title"
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins-SemiBold",
                          color: "#00A978",
                        }}
                      >
                        24 x 7{" "}
                      </span>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "20px",
                          fontFamily: "Poppins-Regular",
                          color: "#636363",
                        }}
                      >
                        Get 24 x 7 real-time insights into your workforce
                      </p>
                    </div>
                  </div>
                  <div className="card py-3 px-2" style={{ border: "none" }}>
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <span
                        className="card-title"
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins-SemiBold",
                          color: "#00A978",
                        }}
                      >
                        Save
                      </span>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "20px",
                          fontFamily: "Poppins-Regular",
                          color: "#636363",
                        }}
                      >
                        Save time & money through smart workforce investment
                        decisions
                      </p>
                    </div>
                  </div>
                  <div className="card py-3 px-2" style={{ border: "none" }}>
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <span
                        className="card-title"
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins-SemiBold",
                          color: "#00A978",
                        }}
                      >
                        Productive
                      </span>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "20px",
                          fontFamily: "Poppins-Regular",
                          color: "#636363",
                        }}
                      >
                        Improve employee engagement & productivity at all levels
                      </p>
                    </div>
                  </div>
                  <div className="card py-3 px-2" style={{ border: "none" }}>
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <span
                        className="card-title"
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins-SemiBold",
                          color: "#00A978",
                        }}
                      >
                        Competitive
                      </span>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "20px",
                          fontFamily: "Poppins-Regular",
                          color: "#636363",
                        }}
                      >
                        Benchmark yourself against other companies and
                        industries
                      </p>
                    </div>
                  </div>
                </div>
              
            </div>

            <div
              className="col"
              style={{
                background: "#FFFFFF",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
              <div className="offset-1 col-10">
                <div className="row">
                  <div className="col-8">
                    <img src={sec3} className="col-12" style={{}} alt="Logo" />
                  </div>
                  <div
                    className="col-md-4"
                    style={{ textAlign: "left", lineHeight: "1.2" , marginTop:'80px' }}
                  >
                    <span
                      className="g2"
                      style={{ fontSize: "36px", fontFamily: "Poppins-Medium" }}
                    >
                      Why Download
                    </span>
                    <br />
                    <span
                      className=""
                      style={{
                        fontSize: "36px",
                        fontFamily: "Poppins-Medium",
                        color: "#10346B",
                      }}
                    >
                      Portfolio One?
                    </span>
                    <br />
                    <div
                      className=""
                      style={{
                        fontSize: "24px",
                        fontFamily: "Poppins-SemiBold",
                        color: "#019FDF",
                        marginTop: "1.25rem",
                      }}
                    >
                      <i
                        className="fa fa-check-circle"
                        style={{ marginRight: "10px", marginBottom:'20px' }}
                      ></i>
                      Analytics
                    </div>
                    <p
                      className=""
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Regular",
                        color: "#636363", marginBottom:'25px'
                      }}
                    >
                      Real estate portfolio analytics to promote reliable
                      workforce planning
                    </p>
                    <div
                      className=""
                      style={{
                        fontSize: "24px",
                        fontFamily: "Poppins-SemiBold",
                        color: "#019FDF",
                        marginTop: "1.25rem",
                      }}
                    >
                      <i
                        className="fa fa-check-circle"
                        style={{ marginRight: "10px", marginBottom:'20px' }}
                      ></i>
                      Insights
                    </div>
                    <p
                      className=""
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Regular",
                        color: "#636363", marginBottom:'25px'
                      }}
                    >
                      Industry-leading, real-time Executive Insights & Content
                      Awareness
                    </p>
                    <div
                      className=""
                      style={{
                        fontSize: "24px",
                        fontFamily: "Poppins-SemiBold",
                        color: "#019FDF",
                        marginTop: "1.25rem",
                      }}
                    >
                      <i
                        className="fa fa-check-circle"
                        style={{ marginRight: "10px", marginBottom:'20px' }}
                      ></i>
                      Data
                    </div>
                    <p
                      className=""
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Regular",
                        color: "#636363",
                      }}
                    >
                      Executive level data to support complex workplace, staff,
                      and financial discussions
                    </p>
                    <div
                      className=""
                      style={{
                        fontSize: "24px",
                        fontFamily: "Poppins-SemiBold",
                        color: "#019FDF",
                        marginTop: "1.25rem",
                      }}
                    >
                      <i
                        className="fa fa-check-circle"
                        style={{ marginRight: "10px", marginBottom:'20px' }}
                      ></i>
                      User-Friedly
                    </div>
                    <p
                      className=""
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Regular",
                        color: "#636363",
                      }}
                    >
                      A tool that's easy to use, even for busy Senior Directors
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="justify-content-center col"
              style={{
                paddingTop: "100px",
                background: "#F4F9FE",
                paddingBottom: "100px",
              }}
            >
              <div className="col-12">
                <div style={{ lineHeight: "1.2" , marginBottom:'30px'}}>
                  <span
                    className=""
                    style={{
                      fontSize: "36px",
                      fontFamily: "Poppins-Medium",
                      color: "#10346B",
                    }}
                  >
                    Elevate your workplace with Portfolio One
                  </span>
                  <br />
                  <span
                    className="g1"
                    style={{ fontSize: "36px", fontFamily: "Poppins-Medium" }}
                  >
                    Get started Today!
                  </span>
                  
                </div>
                <button className="btn col-3  my-2" 
                 onClick={() => {
                  const appStoreUrl = 'https://apps.apple.com/in/app/portfolio-one/id6449759492';
                  window.open(appStoreUrl, '_blank', 'width=600,height=400,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes');
                }}
                style={{ background: "#00A978", color: "#FFFFFF", fontFamily: "Poppins-Medium",  fontSize: "23px", padding:'14px', borderRadius:'112px'  }}><i className="fa fa-lg fa-apple mx-2" ></i>Download the App Now</button>
{/*                 <button type="button" className="btn btn-outline-primary rounded-pill" style={{ paddingLeft: "30px", paddingRight: "30px", borderWidth: "3px", height: "60px", background: "#F4F9FE", color: "#019FDF", borderBlockColor: "#019FDF", fontFamily: "Poppins-Medium", fontSize: "21px" }}>Need Help?</button> 
 */}              </div>
            </div>
            {/* </div> */}

            <div
  className="container-fluid"
  style={{
    background: "#FFFFFF",
    paddingTop: "20px",
    paddingBottom: "20px",
  }}
>
  <div className="row p-0 m-0">
    <div className="col-4" style={{ display: 'flex', justifyContent: 'start' }}>
      <img
        src={portfoliologo}
        style={{ maxHeight: "30px" }}
        alt="Logo"
      />
    </div>
    <div
      className="col-8"
      style={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        textAlign: 'right',
        fontSize: '20px',
        fontFamily: 'Poppins-Regular',
        color: '#636363',
      }}
    >
      <div>
        
        <span>
          <i className="fa fa-copyright" aria-hidden="true"></i> Digital Finance Transformation and contributors 2023
        </span>
        <span
          className=""
          style={{
            fontSize: '20px',
            fontFamily: 'Poppins-Medium',
            color: '#636363',
            cursor: 'pointer',
            marginLeft: '20px',
          }}
          onClick={handleShow}
        >
          Privacy Policy
        </span>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
      
      <Privacypolicy closeModal={handleClose} openModal={showModal} />
      
    </div>
  );
};

export default Introduction;
