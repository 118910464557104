import React,{useState} from "react";
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import fileLogo from '../Images/image 10.png';

const Uploaddoc = () => {
    const [fileattached, setFileattached]= useState(true);
    const dropzoneOptions: DropzoneOptions = {
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/pdf': ['.pdf']
        },
        maxFiles:1
      };
    var {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
      } = useDropzone(dropzoneOptions)
    const removeFile=()=>{
        acceptedFiles=[]
    }
      const acceptedFileItems = acceptedFiles.map((file:any,index:any) => (<>
                <span style={{marginLeft:"10px",marginRight:"10px"}}>{file.path} - {file.size} bytes</span><i className="fa fa-times" style={{color:"red"}} onClick={removeFile} aria-hidden="true"></i>
      </>
      ));
  return (
    <>
      <div className="titlename">Lease Document Upload</div>
      <div style={{marginTop:'100px'}}>
    
    <section className="container" style={{width:"30%"}}>
    <div className='row'>
<div className="col-md-6 form-group" style={{ marginBottom: "10px", display:"flex", alignItems:"end", textAlign:"start" }}>
<label style={{ textAlign: "start" }}>Select file<span style={{ color: "#FE8485" }}>*</span></label>
</div>
<div className="col-md-6 form-group" style={{ marginBottom: "10px", textAlign:"end" }}>
<label style={{ textAlign: "start", fontStyle:'italic', color:'#5C5C5C' }}>Supported formats: </label><label>&nbsp;.pdf</label>
</div>
</div>

  <div {...getRootProps({ className: 'dropzone bulkcontainer' })}>
    <input {...getInputProps()} />
    <p><i className="fa fa-upload fa-3x" style={{color:"#0072B2"}} aria-hidden="true"></i></p>
    <label style={{color:"#25282B", fontFamily:"Poppins-Medium"}}>Drag & drop here</label>
    <label style={{color:"#00000080"}}>or</label>
    <label style={{color:"#0072B2", fontFamily:"Poppins-Medium",cursor:"pointer"}}>Browse File</label>
    {acceptedFiles.length>0?
    <div className='attachedFile'>
    <img src={fileLogo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" />{acceptedFileItems}
    </div>:null}
    
  </div>
  {fileattached?null:
  <div className='row'>
<div className="col-md-6 form-group" style={{ marginBottom: "10px", display:"flex", alignItems:"end", textAlign:"start" }}>
<label style={{ textAlign: "start", color:"#FE8485" }}>Please import .csv or .xlsx file.</label>
</div>
</div>}
</section>
</div>
      
    </>
  );
};

export default Uploaddoc;
