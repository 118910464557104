import React,{useState,useEffect, useRef} from "react";
import portfoliologo from '../Images/portfoliologo.png';
import backgroundWallpaper from '../Images/backgroundwallpaper.svg';
import { RotatingLines } from 'react-loader-spinner';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as yup from 'yup';
import axios from 'axios';
import { baseURL } from "./Apis";

const backgroundStyle = {
    backgroundImage: `url(${backgroundWallpaper})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
  });
  var token:any='';
const Resetpassword = ({ setAuthenticated }: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmpasswordError, setConfirmPasswordError] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tokenError,setTokenError]=useState(false);
  const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = backgroundWallpaper;
    backgroundImage.onload = () => {
      setBackgroundLoaded(true);
    };
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const tokenurl:any = url.searchParams.get('token');
    token=tokenurl
  }, []);

  useEffect(() => {
    if(token=='' || token==undefined || token==null){
      setTokenError(true)
    }
    else{
      setTokenError(false)
    }
  }, []);

  const redirectTologin = () => {
    window.location.href = '../login';
  };

  const redirectToforgot =()=>{
    window.location.href='../forgotpassword';
  }
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

  const changePassword=async()=>{
    setLoader(true)
    if(token!=''){
    try{
      await validationSchema.validate({password:password}, { abortEarly: false });
      if(confirmPassword===password){
        var link = baseURL + 'setNewPassword';
        var datato={
          new_password:password,
          token:token
        }
    axios.post(link, datato)
      .then((response:any) => {
        console.log(response)
        if(response.data.status == 200){
          setLoader(false);
          setDigContent(<span style={{ fontFamily: "Poppins-Medium", fontSize: "18px", color: "#ffffff" }}><i className="fa fa-check-circle fa-lg mx-2" style={{ color: "#00A978" }}></i>Password updated successfully.</span>)
          setModalShow(true);
          const timeout = setTimeout(() => {
             redirectTologin();
            },2000)
            return () => clearTimeout(timeout);
        }
      })
      .catch((error:any) => {
        console.log(error.response.data.error)
        setLoader(false);
        if(error.response.data.error)
        {
          if(error.response.data.error=='Token has expired, time-limit was 10 min, please resend the link & complete the process within 10 min'){
            setDigContent(<span style={{ fontFamily: "Poppins-Medium", fontSize: "18px", color: "#ffffff" }}>Token has been expired. Please try again<i className="fa fa-exclamation mx-2"></i></span>)
            setModalShow(true)
            const timeout = setTimeout(() => {
              redirectToforgot();
            },2000)
              return () => clearTimeout(timeout);
          }
        }
        else{
          setDigContent(<span style={{ fontFamily: "Poppins-Medium", fontSize: "18px", color: "#ffffff" }}>Error occured. Please try again<i className="fa fa-exclamation mx-2"></i></span>)
          setModalShow(true)
          const timeout = setTimeout(() => {
            redirectToforgot();
          },2000)
            return () => clearTimeout(timeout);
        }
        
      });
      }
      else{
        setConfirmPasswordError(true);
        setLoader(false);
      }
    }
    catch{
      setPasswordError(true);
      setLoader(false);
    }}
    else{
      setTokenError(true)
      setLoader(false);
    }
  }

  return (
    <div style={{ ...backgroundStyle, opacity: backgroundLoaded ? 1 : 0 }}>
     {loader == true ?
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center vh-100">
            <div className="card py-5" style={{ width: '100%' }}>
              <div className="card-body container">
                <h5 className="card-title"><img src={portfoliologo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" /></h5>
                <h3 className="my-3">Reset Password</h3>

                <div className="mt-3 offset-1 col-10">
                    <div className="input-container" style={{ position: 'relative' }}>
  <input
    type={showPassword ? 'text' : 'password'}
    value={password}
    className={`form-control rounded-pill inputStyle p-3 ${passwordError ? 'errorBorder' : ''}`}
    id="password"
    placeholder="New password"
    onChange={(e) => { setPassword(e.target.value); setPasswordError(false); }}
    style={{ paddingRight: '2.5rem' }} // Adjust padding for the eye icon
    disabled={tokenError?true:false}
  />
  <div style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)',zIndex:100 }}>
  <i className="fa fa-info-circle icon" style={{ color: "#0072B2", marginRight: '10px' }} data-for="password-tooltip"></i>
    <span className="tooltip-text p-3" style={{textAlign:"left"}}><label>Password must be at least 8 characters long.</label><br/>
                    <label>Password should contain a combination of uppercase and lowercase letters.</label><br/>
                    <label>Password must include at least one number and one special character (!, @, #, etc.)</label><br/>
    </span> {/* Add your tooltip text */}
  </div>
  <div style={{ position: 'absolute', top: '50%', right: '45px', transform: 'translateY(-50%)' }}>
    <i
      className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
      style={{ color: '#0072B2', cursor: 'pointer' }}
      onClick={togglePasswordVisibility}
    ></i>
  </div>
</div>

                    </div>
                    <div className="mt-3 offset-1 col-10">
                    <div className="input-container" style={{ position: 'relative' }}>
  <input
    type='password'
    value={confirmPassword}
    className={`form-control rounded-pill inputStyle p-3 ${confirmpasswordError ? 'errorBorder' : ''}`}
    id="confirmpassword"
    placeholder="Confirm password"
    onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError(false); }}
    style={{ paddingRight: '2.5rem' }} // Adjust padding for the eye icon
    disabled={tokenError?true:false}
  />
  <div style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}>
  <i className="fa fa-info-circle icon" style={{ color: "#0072B2", marginRight: '10px' }} data-for="password-tooltip"></i>
    <span className="tooltip-text p-3" style={{textAlign:"left"}}><label>Please re-enter your new password to confirm.</label><br/>
    </span> {/* Add your tooltip text */}
  </div>
</div>

                    </div>
                    <button type="button" disabled={tokenError?true:false} className="btn btn-block col-10 rounded-pill mt-3 " style={{ backgroundColor: '#0072B2', color: "white" }} onClick={changePassword}>Reset Password</button>
                  {passwordError?
                  <div className="mt-3 offset-1 col-10" style={{textAlign:'left', color: '#FE8485' }}>
                    <label>Password format not matched. Please ensure your password meets the required format:</label><br />
                    <label>Password must be at least 8 characters long.</label>
                    <label>Password should contain a combination of uppercase and lowercase letters.</label>
                    <label>Password must include at least one number and one special character (!, @, #, etc.)</label>
                    <label>Please try again</label>
                  </div>:null}

                  {confirmpasswordError?
                   <div className="mt-3 offset-1 col-10" style={{textAlign:'left', color: '#FE8485' }}>
                    <label>Passwords do not match. Please ensure that the confirm password field matches the original password entered. <br />Try again.</label>
                   </div>
                :null}

{tokenError?
                   <div className="mt-3 offset-1 col-10" style={{textAlign:'left', color: '#FE8485' }}>
                    <label>Authentication token is required to change password. <br />Try again with correct reset password url.</label>
                   </div>
                :null}
                  
                  <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                    <label style={{ color: '#0072B2', cursor: "pointer" }} onClick={redirectTologin}>Remember password? login now.</label>
                  </div>
    </div>
    </div>
    </div>
    </div>
    </div>


    <Dialog
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          onClick: () => { }, // Disable backdrop click
        }}
        PaperProps={{
          style: {
            textAlign: 'center',
            justifyContent:'center',
            position: 'absolute',
            top: '10%', // Adjust the distance from the top as needed
            },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {digContent}
          </DialogContentText>
        </DialogContent>
      </Dialog>
 
    </div>
  );
};

export default Resetpassword;