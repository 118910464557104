import React,{useState,useEffect, useRef} from "react";
import portfoliologo from '../Images/portfoliologo.png';
import backgroundWallpaper from '../Images/backgroundwallpaper.svg';
import * as yup from 'yup';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { baseURL } from "./Apis";

const validationSchema = yup.object().shape({
  email: yup.string().required().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)})

const backgroundStyle = {
    backgroundImage: `url(${backgroundWallpaper})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
  };


const Forgotpassword = ({ setAuthenticated }: any) =>
{
const [backgroundLoaded, setBackgroundLoaded] = useState(false);
const [activeSection,setActiveSection]=useState(1);
const [email,setEmail]=useState('');
const[emailError,setEmailError]=useState(false);
const [loader, setLoader] = useState(false)


const redirectTologin = () => {
    window.location.href = '../login';
  };

const sendResetLink = async()=>{
  setLoader(true);
  try{
  await validationSchema.validate({email:email}, { abortEarly: false })
  var link = baseURL+ 'sendPasswordResetEmail';
  var data={
    email:email
  }
    axios.post(link, data)
      .then((response:any) => {
        console.log(response)
        if(response.data.status == 200){
          setActiveSection(2)
          setLoader(false);
          const timeout = setTimeout(() => {
            setLoader(true)
            const timeout1 = setTimeout(() => {
              redirectTologin()
              },2000)
              return () => clearTimeout(timeout1);
            },2000)
            return () => clearTimeout(timeout);
        }
      })
      .catch((error:any) => {
        setEmailError(true);
        setLoader(false);
      });
    }
    catch{
      setEmailError(true);
      setLoader(false);
    }
}
useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = backgroundWallpaper;
    backgroundImage.onload = () => {
      setBackgroundLoaded(true);
    };
  }, []);
return(
    <div style={{ ...backgroundStyle, opacity: backgroundLoaded ? 1 : 0 }}>
       {loader == true ?
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center vh-100">
            <div className="card py-5" style={{ width: '100%' }}>
              <div className="card-body container">
                <h5 className="card-title"><img src={portfoliologo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" /></h5>
                <h3 className="my-3">Forgot Password</h3>
                {activeSection==1?
                <form>
                  <div className="mb-3 offset-1 col-10">
                    <input type="text" className={`form-control rounded-pill inputStyle p-3 ${emailError?'errorBorder':''}`} id="username" placeholder="Enter your email" onChange={(e)=>{setEmail(e.target.value);setEmailError(false)}}/>
                  </div>
                  {emailError?<>
                    <div className="mb-3 offset-1 col-10">
                  <label className='' style={{fontFamily:"Poppins-Medium", color:'#FE8485'}}>Please enter valid registered email id.</label>
                  </div>
                  </>:null}
                  {!emailError?
                  <div className="mb-3 offset-1 col-10">
                  <label className='' style={{fontFamily:"Poppins-Medium"}}>We'll send a link to reset password to this email if it matches an existing account.</label>
                  </div>:null}
                  <button type="button" className="btn btn-block col-10 rounded-pill" style={{ backgroundColor: '#0072B2', color: "white" }} onClick={sendResetLink}>Send</button>
                  <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                    <label style={{ color: '#0072B2', cursor: "pointer" }} onClick={redirectTologin}>Remember password? login now.</label>
                  </div>
                </form>:null}
                {
                  activeSection==2?<>
                  <div className="container">
                  <div className="mb-3 offset-1 col-10">
                  <label className='' style={{fontFamily:"Poppins-Medium"}}>A password reset link has been successfully sent to your registered email address.
                  Click on the provided link within the email. This link will direct you to a page where you can reset your password.</label>
                  </div>
                  </div>
                  </>:null
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

  )
}

export default Forgotpassword; 