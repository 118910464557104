import React, { useEffect, useState } from "react";
import portfoliologo from "../Images/portfoliologo.png";
import backgroundWallpaper from '../Images/backgroundwallpaper.svg';
import { useNavigate } from "react-router-dom";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import axios from "axios";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { baseURL } from "./Apis";

const backgroundStyle = {
  backgroundImage: `url(${backgroundWallpaper})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
};

var token: any = "";

const Verifyemail = () => {
  const navigate = useNavigate();
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [error,setError]=useState(false);
  const callLogin = () => {
    navigate("/login");
  };
  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = backgroundWallpaper;
    backgroundImage.onload = () => {
      setBackgroundLoaded(true);
    };
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const tokenurl: any = url.searchParams.get("token");
    token = tokenurl;
    if (token) {
    var link = baseURL + "verifyEmail?token=" + token;
    axios
      .get(link)
      .then((response) => {
        setError(false);
      })
      .catch((error) => {
        setError(true);
      });}
      else{
        setError(true);
      }
  }, []);
  return (
    <>
      <div style={{ ...backgroundStyle, opacity: backgroundLoaded ? 1 : 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center vh-100">
              <div className="card py-5" style={{ width: "100%" }}>
                <div className="card-body container">
                  <h5 className="card-title">
                    <img
                      src={portfoliologo}
                      style={{ maxHeight: "4vh", maxWidth: "40vw" }}
                      alt="Logo"
                    />
                  </h5>
                  <h5>
                    {error?<ReportProblemIcon
                      style={{ fontSize: "100px", color: "#FF5656" }}
                    />:
                    <MarkEmailReadIcon
                    style={{ fontSize: "100px", color: "#0072B2" }}
                  />
                    }
                  </h5>
                  {error?
                  null:<label style={{ fontSize: "30px", fontWeight: "600" }}>
                  Welcome
                </label>}
                  <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                    {error?<label style={{ fontSize: "20px", fontWeight: "400", color:'#FF5656'}}>
                      Error occurred. Try again.
                    </label>:<label style={{ fontSize: "20px", fontWeight: "400" }}>
                      Your account has been verified.
                    </label>}
                  </div>
                 {error? null:<button
                    type="button"
                    className="mt-3 btn btn-block col-10 rounded-pill"
                    style={{
                      backgroundColor: "#0072B2",
                      color: "white",
                      fontWeight: "500",
                    }}
                    onClick={() => callLogin()}
                  >
                    Login
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verifyemail;
