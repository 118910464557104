import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './Login'; // Import your Login component
import Home from './Home'; // Import your Home component
import Forgotpassword from './Forgotpassword';
import Register from './Register';
import Introduction from './Introduction';
import Resetpassword from './Resetpassword';
import Verifyemail from './Verifyemail';
import Privacypage from './Privacypage';
import Termsofuse from './Termsofuse';

function Routing() {
  const [authenticated, setAuthenticated] = useState(
    sessionStorage.getItem('authenticated') === 'true'
  );
  const navigate = useNavigate();

  useEffect(() => {
    const storedAuth = sessionStorage.getItem('authenticated') === 'true';
    if (!authenticated && storedAuth) {
      setAuthenticated(true);
    }
  }, [authenticated]);

  const handleLogin = () => {
    setAuthenticated(true);
    sessionStorage.setItem('authenticated', 'true');
  };

  const handleLogout = () => {
    setAuthenticated(false);
    sessionStorage.clear();
    sessionStorage.setItem('authenticated', 'false');
    navigate('/login');
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={<Login setAuthenticated={handleLogin} />}
      />
      <Route
        path="/forgotpassword"
        element={<Forgotpassword setAuthenticated={setAuthenticated} />}
      />
      <Route
        path="/home/*"
        element={
          authenticated ? (
            <Home onLogout={handleLogout} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/reset_password/*"
        element={<Resetpassword setAuthenticated={setAuthenticated} />}
      />
      <Route
        path="/verify_email/*"
        element={<Verifyemail  />}
      />
      <Route
        path="/register"
        element={<Register setAuthenticated={setAuthenticated} />}
      />
      <Route
        path="/privacy-policy"
        element={<Privacypage />}
      />
      <Route
        path="/terms-of-use"
        element={<Termsofuse />}
      />

      <Route path="*" element={<Introduction />} />
    </Routes>
  );
}

export default Routing;
