import React, { useEffect, useState } from 'react';
import portfoliologo from '../Images/portfoliologo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import '../CSS/common.css';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import Accesserrormodal from './Accesserrormodal';
import Avatar from 'react-avatar';



const navbarStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
  paddingLeft: '15px',
  paddingRight: '15px',
};

const separatorStyle = {
  borderLeft: '1px solid #ccc',
  height: '20px', // Adjust the height to vertically center the text
  paddingLeft: '15px',
};

const Navigationbar = ({onLogOut, activeSection, onSectionChange}:any) => {
  const navigate = useNavigate();
  var subscribedFlag:any=sessionStorage.getItem('subscribedFlag')==undefined?'false':sessionStorage.getItem('subscribedFlag');
  var userRoleId:any=sessionStorage.getItem('userRoleId')==undefined?'':sessionStorage.getItem('userRoleId');
  var userRank:any=sessionStorage.getItem('userAccessRank')=== undefined ?'0':sessionStorage.getItem('userAccessRank');
  var userName:any=sessionStorage.getItem('username')===undefined?'':sessionStorage.getItem('username');
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [modalType,setModalType]=useState('');


  const toTitleCase=(str:any)=> {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match:any) {
      return match.toUpperCase();
    });
  }
  const pageRefresh=()=>{
    window.location.reload();
  }
  const handleLogout=()=>{
    onLogOut()
  }

  const navigateSingle =()=>{
    if(userRank=='4')
    {
      setModalType('4');
          handleShow();
    }
    else{navigate('/home/addSite/singleSite')}
  }
  
  const navigateBulk =()=>{
    if(userRank=='4')
    {
      setModalType('4');
      handleShow();
    }else{
    navigate('/home/addSite/bulkSites')}
  }
  return (<>

    <nav className="navbar navbar-expand-lg navbar-light " style={{...navbarStyle,position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 99,
    background:'#FFFFFF',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Example shadow effect
    
    }}>
      <h2 className="card-title">
        
      </h2>
      <div className="d-flex align-items-center">
        {/* <span className="nav-link mx-3" style={activeSection!='dashboard' && activeSection!='home'?{fontFamily:"Poppins-SemiBold",cursor:"pointer"}:{fontFamily:"Poppins-Bold",cursor:"pointer",color: '#0072B2'}} onClick={()=>{navigate('/home/dashboard');onSectionChange('dashboard')}} >Dashboard</span>
        <span className="nav-link mx-3" style={activeSection!='siteList'?{fontFamily:"Poppins-SemiBold",cursor:"pointer"}:{fontFamily:"Poppins-Bold",cursor:"pointer",color: '#0072B2'}} onClick={()=>{navigate('/home/siteList');onSectionChange('siteList')}} >Site List</span>
        */} <div className="dropdown">
          {userRoleId!=='1' && userRank!='2'?
  <button className="btn btn-block rounded-pill mx-3 dropdown-toggle" style={{backgroundColor: '#0072B2', color:"white",fontFamily:"Poppins-Medium"}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Add Site
  </button>:null}
  <ul className="dropdown-menu p-2" style={{backgroundColor:"#DCF2FF", border:"none", boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.25)"}}>
    <li><button className="dropdown-item drit px-0" type="button" style={{color:"#0072B2",fontFamily:"Poppins-Medium", borderBottom:"1px solid #698595",cursor:"pointer"}} onClick={(e:any)=>{navigateSingle();onSectionChange('addsite')}}>Single Site</button></li>
    <li>
      <button className={`dropdown-item drit px-0 ${subscribedFlag=='true'?'':'disable disabled'}`} type="button" 
      style={subscribedFlag=='true'?{color:"#0072B2",fontFamily:"Poppins-Medium",cursor:"pointer"}:{color:"#a7a3a3",fontFamily:"Poppins-Medium",cursor:'not-allowed'}} onClick={(e:any)=>{navigateBulk();onSectionChange('addsite')}}>Bulk Upload</button></li>
  </ul>
</div>
<div style={{marginRight:"10px",padding:"10px",border:"1px solid #D9D9D9", borderRadius:"10px"}}>
<SyncRoundedIcon style={{color:"#0072B2", fontSize:"30px", cursor:"pointer"}} onClick={pageRefresh} />
</div>

        <div style={separatorStyle}></div>
        <span style={{fontFamily:"Poppins-Medium"}}>
        <Avatar
         name={userName}
         size="50"
         color='#0072B2'
         round
         style={{ borderRadius: '50%', marginRight:'10px', fontWeight:'700' }}
         className='avtarheader'
      />{toTitleCase(sessionStorage.getItem('username')===undefined?'':sessionStorage.getItem('username'))}</span>
        <span style={{fontFamily:"Poppins-Bold",color:"#0072B2",cursor:"pointer"}} className='mx-3' onClick={handleLogout}><i className="fa fa-sign-out fa-lg" aria-hidden="true"></i></span>
      </div>
    </nav>
    <Accesserrormodal closeModal={handleClose} openModal={showModal} modalType={modalType} />
      </>
  )
}

export default Navigationbar