import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import portfoliologo from "../Images/portfoliologo.png";

const Privacypolicy =({closeModal,openModal}:any)=>{
    return(
<Modal
        className="policyModal modal-xl"
        centered
        show={openModal}
        onHide={closeModal} 
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <h5 className="card-title">
              <img
                src={portfoliologo}
                style={{ maxHeight: "4vh", maxWidth: "40vw" }}
                alt="Logo"
              />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "700px" }} className="overflow-auto">
          <p>Digital Finance Transformation, LLC Data Privacy Policy</p>
          <p>Effective Date : December 1, 2023</p>
          <ol>
            <li>
              <p>Introduction</p>
              <p>
                Digital Finance Transformation, LLC ("we," "our," or "us") is
                committed to protecting the privacy and security of your
                personal information. This Data Privacy Policy outlines the
                types of personal information we collect, how we use it, and the
                choices you have concerning your data.
                <br />
                Data privacy is defined as per the State of Connecticut general
                statutes 743DD.
                <br />
                Act Concerning Personal Data Privacy and Online Monitoring (also
                known as The Connecticut Data Privacy Act or “CTDPA”). Specific
                details can be referenced at :{" "}
                <a href="https://portal.ct.gov/Policies/Connecticut-Personal-Data-Act">
                  CT.gov: Connecticut Personal Data Act
                </a>{" "}
              </p>
            </li>
            <li>
              <p>Information We Collect</p>
              <p>
                When you use our application, we may collect personal
                information, such as:
                <ul>
                  <li>Employee Name</li>
                  <li>Employee email</li>
                  <li>Employee Telephone</li>
                  <li>Employee Organization</li>
                </ul>
              </p>
            </li>
            <li>
              <p>How We Use Your Information</p>
              <p>
                We use your personal information for the following purposes:
                <ul>
                  <li>
                    {" "}
                    Provide data aggregation and analytics supporting workplace
                    and workspace portfolio analysis
                  </li>
                  <li> Assess the organization's workplace profile</li>
                  <li>
                    {" "}
                    Assess the organization's workspace current and future needs
                  </li>
                  <li>
                    {" "}
                    Aggregate data to inform management on potential workspace
                    planning scenarios
                  </li>
                </ul>
              </p>
              <p>
                We may collect information about how you use the Application,
                including:
                <ul>
                  <li> Log data </li>
                  <li> Device information</li>
                  <li> Cloud-based infrastructure utilization</li>
                  <li> Cloud-based services utilization</li>
                </ul>
              </p>
              <p>
                We use the collected information to provide, maintain, and
                improve the functionality of the Application.
              </p>
            </li>

            <li>
              <p>Communications</p>
            </li>
            <p>
              We may use your email address to send you important updates,
              newsletters, or other information.
            </p>

            <li>
              <p>Legal Basis for Processing</p>
            </li>
            <p>
              We process your personal information based on the following legal
              grounds:
              <ul>
                <li> Consent from the sponsoring organization</li>
                <li>
                  {" "}
                  A contractual necessity to understand current workplace
                  organization constructs
                </li>
              </ul>
            </p>

            <li>
              <p>Data Sharing</p>
            </li>
            <p>
              We have no reason to share your personal information with third
              parties.
            </p>

            <li>
              <p>Data Retention</p>
            </li>
            <p>
              We will retain your personal information only for as long as is
              necessary for the purposes set out in this Privacy Policy.
            </p>

            <li>
              <p>Your Rights</p>
            </li>
            <p>
              You have the following rights regarding your personal information:
              <ul>
                <li>Access, correct, or delete your personal information.</li>
                <li>Object to the processing of your personal information.</li>
                <li>
                  Receive a copy of your personal information in a structured,
                  machine-readable format.
                </li>
              </ul>
            </p>

            <li>
              <p>Security</p>
            </li>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access,
              disclosure, alteration, and destruction.
            </p>

            <li>
              <p>International Data Transfers</p>
            </li>
            <p>
              If we transfer your personal information outside the European
              Economic Area (EEA), we will ensure appropriate safeguards are in
              place.
            </p>

            <li>
              <p>GDPR Compliance</p>
            </li>
            <p>
              Our data processing activities comply with the General Data
              Protection Regulation (GDPR). For any GDPR-related inquiries or
              requests, please contact us at support@portfolioone.io.
              <br />
              Specifically, the following processes will be followed:
            </p>
            <p>
              <ul>
                <li>
                  A data protection officer (DPO) will be assigned if our
                  processes require collecting sensitive data on a large scale.
                </li>
                <li>
                  We will obtain explicit consent from individuals before
                  processing their personal data unless authorized by their
                  representatives and or organizations of employment with
                  internal approvals of employees
                </li>
                <li>
                  Implement appropriate technical and organizational measures to
                  ensure the security of personal data.
                </li>
                <li>
                  Report data breaches to the relevant supervisory authority
                  within 72 hours of becoming aware of the breach.
                </li>
                <li>
                  Provide individuals with the right to access, rectify, and
                  erase their personal data.
                </li>
                <li>Comply with data portability requirements.</li>
                <li>
                  Conduct data protection impact assessments (DPIAs) for
                  high-risk processing activities.
                </li>
              </ul>
            </p>
            <li>
              <p>Changes to this Privacy Policy</p>
            </li>
            <p>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be effective immediately upon the
              posting of the revised Privacy Policy.
            </p>
            <li>
              <p>Contact Us</p>
            </li>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at support@portfolioone.io. By using the
              Application, you agree to the terms of this Privacy Policy.
            </p>
          </ol>
          <br />
          <p>
            Digital Finance Transformation, LLC
            <br />
            14 Longate Road
            <br />
            Clinton, CT 06413
            <br />
            Portfolioone.io
          </p>
        </Modal.Body>
        <Modal.Footer className="mt-auto d-flex justify-content-center">
         <Button className="submit" onClick={closeModal}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default Privacypolicy;