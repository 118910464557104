import React, { useState, useEffect } from "react";
import "../CSS/common.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const UserAccountAQ: any = [
  {
    que: "How do I login into Admin portal?",
    ans: (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
          <li>
            Visit <a href="www.portfolioone.io/login">Admin Portal</a>
          </li>
          <li>Enter your login details.</li>
          <li>Click "Login" to access your account.</li>
        </ul>
      </div>
    ),
  }
];
 

 
const UserAccountAQ1: any = [
  {
    que: "How to Add site in Admin portal?",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>To add a site in the Admin portal, follow these steps: </span>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
          <li>Log in to the <a href="www.portfolioone.io/login">Admin Portal</a>.</li>
          <li>Click on "Add Site" in the header.</li>
          <li>Select "Single Site."</li>
          <li>You will be navigated to the site details page.</li>
          <li>Add all the required details.</li>
          <li>Click "Add Site."</li>
        </ul>
      </div>
      <span style={{ display: "flex", justifyContent: "left",marginTop:'5px' }}>The site will be successfully added to the Admin portal.</span>
      </>
    ),
  },
  {
    que: "Can I add multiple Sites at same time?",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>Yes, you can add multiple sites simultaneously. Follow these steps:</span>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
          <li>Log in to the <a href="www.portfolioone.io/login">Admin Portal</a>.</li>
          <li>Click on "Add Site" in the header.</li>
          <li>Select "Bulk Upload." </li>
          <li>On the Bulk Upload page, click "Sample Data File" to download the Excel file. </li>
          <li>Enter all the site details in the Excel file in the given format. </li>
          <li>Upload the Excel file on the Bulk Upload page by either drag-and-drop or browse file.</li>
          <li>Click "Submit." </li>
        </ul>
      </div>
      <span style={{ display: "flex", justifyContent: "left",marginTop:'5px' }}>This process allows you to efficiently add multiple sites to the Admin portal simultaneously. </span>
      </>
    ),
  },
  {
    que: "How to view the added sites in Admin portal? ",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>To view the added sites in the Admin portal, follow these steps: </span>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
          <li>Log in to the <a href="www.portfolioone.io/login">Admin Portal</a>.</li>
          <li>Click on "Site List" in the side navigation pannel.</li>
          <li>You will see the added sites displayed. </li>
          </ul>
          </div>
      </>
    ),
  },
  {
    que: "How can I make changes to the added sites?",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>To make changes to the added sites, follow these steps: </span>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
        <li>Log in to the <a href="www.portfolioone.io/login">Admin Portal</a>.</li>
          <li>Click on "Site List" in the side navigation pannel.</li>
          <li>You will see the added sites displayed. </li>
          <li>Click the Edit icon for the required site under "Actions."</li>
          <li>The added site will be displayed in editable mode. </li>
          <li>Make changes to the site details. </li>
          <li>Click "Update Site." </li>
        </ul>
      </div>
      <span style={{ display: "flex", justifyContent: "left",marginTop:'5px' }}>By following these steps, you can easily make changes to the added site. </span>
      </>
    ),
  },
  {
    que: "How do I delete a site after it was added? ",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>To deactivate a site from the Admin portal, follow these steps:</span>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <ul style={{ textAlign: "left" }}>
        <li>Log in to the <a href="www.portfolioone.io/login">Admin Portal</a>.</li>
          <li>Click on "Site List" in the side navigation pannel.</li>
          <li>You will see the added sites displayed. </li>
          <li>urn off the toggle button for the respective site under "Actions."</li>
        </ul>
      </div>
      <span style={{ display: "flex", justifyContent: "left",marginTop:'5px' }}>By following these steps, you can easily deactivate a site. </span>
      </>
    ),
  },
  {
    que: "What is the significance of latitude and longitude while adding a site?",
    ans: (<>
      <span style={{ display: "flex", justifyContent: "left",marginBottom:'10px' }}>Latitude and longitude play a significant role in adding sites.</span>
      <div style={{ display: "flex", justifyContent: "left" }}>
      The correct latitude and longitude ensure that the site is accurately positioned on the globe in its exact location. </div>
      </>
    ),
  }
];
const Faq = () => {
  const [activeCat, setActiveCat] = useState("cat1");
  const [accordions, setAccordionStates] = useState([true]);
  const [accordions1, setAccordionStates1] = useState([true,false,false,false,false,false,false]);

  return (
    <>
      <div className="titlename">FAQ</div>
      <button
        type="button"
        className={`btn btn-block rounded-pill mx-2 faqcategory ${
          activeCat == "cat1" ? "activecat" : ""
        }`}
        onClick={(e) => setActiveCat("cat1")}
      >
        User Account
      </button>
{/*       <button
        type="button"
        className={`btn btn-block rounded-pill mx-2 faqcategory ${
          activeCat == "cat2" ? "activecat" : ""
        }`}
        onClick={(e) => setActiveCat("cat2")}
      >
        Subscription
      </button> */}
      <button
        type="button"
        className={`btn btn-block rounded-pill mx-2 faqcategory ${
          activeCat == "cat3" ? "activecat" : ""
        }`}
        onClick={(e) => setActiveCat("cat3")}
      >
        Site
      </button>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          fontFamily: "Poppins-Medium",
          fontSize: "28px",
          color: "#000",
        }}
      >
        <span>Welcome. How can we help you?</span>
      </div>
      {activeCat==='cat1'?<>
      {UserAccountAQ.map((data: any, key: any) => {
        return (
          <Accordion
            defaultExpanded={accordions[key] == true ? true : false}
            className="FaqAc"
            onChange={(e) => {
              const newState = [...accordions];
              newState[key] = !newState[key];
              setAccordionStates(newState);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key + 2}-content`}
              id={`panel${key + 2}-header`}
              className="FaqAcQue"
            >
              {data.que}
            </AccordionSummary>
            <AccordionDetails className="FaqAcAns">{data.ans}</AccordionDetails>
          </Accordion>
        );
      })} </>:null}
      {activeCat==='cat3'?<>
      {UserAccountAQ1.map((data: any, key: any) => {
        return (
          <Accordion
            defaultExpanded={accordions1[key] == true ? true : false}
            className="FaqAc"
            onChange={(e) => {
              const newState = [...accordions1];
              newState[key] = !newState[key];
              setAccordionStates1(newState);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key + 2}-content1`}
              id={`panel${key + 2}-header1`}
              className="FaqAcQue"
            >
              {data.que}
            </AccordionSummary>
            <AccordionDetails className="FaqAcAns">{data.ans}</AccordionDetails>
          </Accordion>
        );
      })} </>:null}

    </>
  );
};

export default Faq;
