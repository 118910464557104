import React,{useState, useEffect} from "react";
import '../CSS/common.css';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';
import orgicon from '../Images/orgicon.svg';
import { RiPencilFill } from "react-icons/ri";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { baseURL } from "./Apis";
import Avatar from 'react-avatar';
import { TbFilterDown } from "react-icons/tb";
import { RotatingLines } from "react-loader-spinner";

const Orgmanagement = () => {
  const user_id:any =
  sessionStorage.getItem("user_id") === undefined
    ? 0
    : sessionStorage.getItem("user_id");
    const org_id:any =
    sessionStorage.getItem("org_id") === undefined
      ? 0
      : sessionStorage.getItem("org_id");
  const authToken =
  sessionStorage.getItem("apiToken") === undefined
    ? ""
    : sessionStorage.getItem("apiToken");
    const userRoleId:any =
    sessionStorage.getItem("userRoleId") === undefined
      ? 0
      : sessionStorage.getItem("userRoleId");
  const [employerName,setEMpName]=useState('');
  const [employerRole,setEMpRole]=useState('');
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [showModal1, setShowModal1] = useState(false);
  const handleShow1 = () => setShowModal1(true);
  const handleClose1 = () => setShowModal1(false);
  const [userList,setUserList]=useState([]);
  const [roleList,setRoleList]=useState([]);
  const [notAssignedUsers, setNotAssignedUsers]=useState([]);
  const [managerrId, setManagerId]=useState(null);
  const [userName, setUserName]=useState('');
  const [roleId, setRoleId]=useState('');
  const [userRoleList, setUserRoleList]=useState([]);
  const [userRoleListTemp, setUserRoleListTemp]=useState([]);
  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [managerDetails,setManagerDetails]=useState({
    created_at: "",
    email: '',
    fullname: '',
    is_deleted: 0,
    is_email_verified: 1,
    mobile: 1234567890,
    org_id: 1,
    organisation: '',
    password: '',
    reports_to: 109,
    role_id: 6,
    user_id: 134,
    role_name:''
});
const [empUnder, setEmpUnder]=useState([]);

    const columnDefs=[
        {name:'User Name',selector: (row:any) => row.fullname,sortable: true},
        {name:'Role Name',selector: (row:any) => titleCase(row.role_name),sortable: true},
        {name:'Action',
        cell: (row: any) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className=""
                src={orgicon}
                style={{
                  cursor: "pointer",
                  height: "18px",
                  width: "18px",
                }}
                aria-hidden="true"
                onClick={async() => {
                  setLoader1(true)
                  await setManagerDetails(row);
                  handleShow1();
                  await getChildEmp(row.user_id);
                  setLoader1(false)
                  
                }} />
                
                      <RiPencilFill
                        style={{
                          color: "#0072B2",
                          cursor: "pointer",
                          height: "18px",
                          width: "18px",
                          marginLeft:'10px'
                        }}
                        aria-hidden="true"
                        onClick={async() => {
                          setLoader(true)
                          getNotAssignedEmp(org_id)
                          setEMpName(row.fullname)
                          setEMpRole(row.role_id)
                          setManagerId(row.user_id)
                          await getChildEmp(row.user_id);
                         handleShow() 
                         setLoader(false);
                        }}
                      />
              
            </div>
          ),}
    ]
    

    //==============================================================================

    useEffect(()=>{
       fetchData();
    },[])

    const titleCase=(str:any)=>{
      return str.toLowerCase().split(' ').map((word :any)=> word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    
    const fetchData=async()=>{
      setLoader(true);
      var users:any;
      try{
      var userByOrg = await axios.get(`${baseURL}/getUsersByOrgId/`+org_id,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        }})
      //console.log(userByOrg.data.users)
      if(userByOrg.data.status=200){
        setUserList(userByOrg.data.users);
        
      }
    }
    catch(error){
      console.log(error)
      setLoader(false);
    }
    try{
      var userByOrg = await axios.get(`${baseURL}/getRoles`,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        }})
      //console.log(userByOrg.data.data)
      if(userByOrg.data.status=200){
        var tempArray:any =[];
        userByOrg.data.data.map((val:any,key:any)=>{
            if(val.role_id>=+userRoleId)
            {
              tempArray.push(val);
            }
        })
        setRoleList(tempArray);
        setLoader(false)
      }
    }
    catch(error){
      console.log(error)
      setLoader(false)
    }

    try{
      var userByOrg = await axios.get(`${baseURL}/getNotAssignedUsers/`+org_id,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        }})
      //console.log(userByOrg.data.users)
      if(userByOrg.data.status=200){
        setNotAssignedUsers(userByOrg.data.users);
        
      }
    }
    catch(error){
      console.log(error)
      setLoader(false);
    }
    setLoader(false)
    }

    const getChildEmp = async(userId:any)=>{
      try{
        var userByOrg = await axios.get(`${baseURL}/getUsersByManager/`+userId,{
          headers: {
            Authorization: `Bearer ${authToken}`,
          }})
        if(userByOrg.data.status=200){
          const tempArray = userList.filter((user:any) => {
            return userByOrg.data.users.some((orgUser:any) => orgUser.user_id === user.user_id);
          });
          //console.log(tempArray);
          setEmpUnder(tempArray)
        }
      }
      catch(error){
        console.log(error)
      }
    }

    const findRoleDetails = (roleId:any) => {
      const role:any = roleList.find((role:any) => role.role_id === roleId);
      return role ? role.role_name : ''; // 
    };

    const handleRoleChange=(e:any)=>{
      setEMpRole(e.target.value)
    }

    const getNotAssignedEmp =async(orgId:any)=>{
      try{
        var userByOrg = await axios.get(`${baseURL}/getNotAssignedUsers/`+org_id,{
          headers: {
            Authorization: `Bearer ${authToken}`,
          }})
        if(userByOrg.data.status=200){
          setNotAssignedUsers(userByOrg.data.users);
        }
      }
      catch(error){
        console.log(error)
      }
    }

    const updateRole = async()=>{
    var dataToAttach = {
      new_role_id: employerRole,
    };
    axios
      .put(`${baseURL}/updateUserRole/` + managerrId, dataToAttach, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(async(data) => {
        //console.log(data);
        await fetchData();
      })
      .catch((error) =>
        console.error("Error fetching user data in AnotherFile:", error)
      );
    handleClose();
    }

    const updateManager =async(userData:any,action:any)=>{
      var data:any=[];
      var manager_id:any=action=='remove'?null:managerrId;
      if(action=='remove'){
        var tempObj={
          user_id:userData.user_id,
          reports_to: manager_id
        }
        data.push(tempObj);
      }
      else{
        userData.map((val:any,key:any)=>{
          var temp:any={
            user_id:val.user_id,
            reports_to:manager_id
          }
          data.push(temp)
        })
      }
      
      try{
        var userByOrg = await axios.put(`${baseURL}/updateReportsTo`,data,{
          headers: {
            Authorization: `Bearer ${authToken}`,
          }})
        //console.log(userByOrg)
        if(userByOrg.data.status=200){
          getNotAssignedEmp(org_id);
        }
      }
      catch(error){
        console.log(error)
      }
    }

    const onSubmitData=async()=>{
      setLoader(true);
      handleClose();
      await updateManager(empUnder,'addunder');
      await updateRole();
      //etLoader(false);s
    }
    return(
        <>
        {loader ? (
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      ) : (<>
        <div className="titlename">
        Organization Management
    </div>
    {/* <div style={{display:'flex',justifyContent:'right'}}>
    <button type='button' className="managementbutton btn btn-block rounded-pill" >Manage Organization</button>
    </div> */}
    <DataTable
            title={<div className="managementTableTitle">User List</div>}
            columns={columnDefs}
            data={userList}
            pagination
            fixedHeader
            responsive
            fixedHeaderScrollHeight="700px"
            className='managementTable'
            defaultSortFieldId='role_name'
            />

<Modal className="managementmodal modal-xl" centered show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontFamily:'Poppins-Medium'}}>Organization Chart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="container">
      <div className="row">
        <div className="col-6">
          <label className="modalbodylabel">User Name</label>
          <input className="form-control modalbodyinput" aria-label="Default select example" disabled value={employerName}/>
        </div>
        <div className="col-6">
          <label className="modalbodylabel">Select Role</label>
          <select className="form-select modalbodyinput" value={employerRole}
                  onChange={handleRoleChange}>
            {roleList.map((val:any,key:any)=>{
              return(
                <option key={key} value={val.role_id}>{titleCase(val.role_name)}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div>
      <label className="modalbodylabel">Select Employee</label>
        <Multiselect
          className="modalbodyinput"
          displayValue="fullname"
          onKeyPressFn={() => {}}
          onRemove={(selectedList, removedItem) => {
            const updatedSelectedPermissions = empUnder.filter(
              (user:any) => user.user_id !== removedItem.user_id
            );
            setEmpUnder(updatedSelectedPermissions);
            updateManager(removedItem,'remove')
          }}
          onSearch={() => {}}
          onSelect={(selectedList, selectedItem) => {
            var tempdata:any=empUnder;
                tempdata.push(selectedItem);
                setEmpUnder(tempdata);
          }}
          options={notAssignedUsers}
          selectedValues={empUnder}
        />
      </div>
    </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="submit" style={{fontFamily:'Poppins-Medium'}} onClick={()=>{onSubmitData()}}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>


    <Modal className="managementmodal modal-xl" centered show={showModal1} onHide={handleClose1} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontFamily:'Poppins-Medium'}}>Organization Chart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="d-flex flex-column align-items-center mt-5">
      {/* Top card */}
      <div className="card mb-3" style={{ width: "25rem" }}>
        <div className="card-body">
        <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        name={managerDetails.fullname}
        size="80"
        round
        style={{ borderRadius: '50%' }}
      />
      <div style={{ marginLeft: '10px' }}>
        <span className="card-title orgEmpName">{managerDetails.fullname}</span><br/>
        <span className="card-title orgRoleName">{titleCase(managerDetails.role_name)}</span><br/>
        <span className="card-title orgNameCard">{managerDetails.organisation}</span>
      </div>
    </div>
        </div>
      </div>

      {/* Vertical line */}
      <div className="vertical-line"></div>

      {/* Bottom card */}
      
      {loader1?
 
      <div id="loader">
        <RotatingLines
          strokeColor="#0072B2"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
      :
      <div className="card col-12">
      <div className="row " style={{marginLeft:'0px'}}>
        <label className="reportingLable">People reporting to {managerDetails.fullname} {'('+empUnder.length+')'}</label>
        </div>
      <div className="card-body row " style={{marginLeft:'0px'}}>
        {empUnder.map((val:any, key:any) => (
          <div
            key={key}
            className="card col-4"
            style={{  border: 'none' }}
          >
            <div className="card-body">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar name={val.fullname} size="60" round style={{ borderRadius: '50%' }} />
                <div style={{ marginLeft: '10px' }}>
                  <span className="card-title orgEmpName">{val.fullname}</span>
                  <br />
                  <span className="card-title orgRoleName">{titleCase(findRoleDetails(val.role_id))}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>}
    </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="submit" style={{fontFamily:'Poppins-Medium'}} onClick={handleClose1}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
    </>)}
    </>
  );
}

export default Orgmanagement;