import React, { useEffect, useState } from "react";
import "../CSS/common.css";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { baseURL } from "./Apis";
import { RiPencilFill } from "react-icons/ri";
import { FaTimes } from 'react-icons/fa';
import { FaTelegramPlane } from "react-icons/fa";

const Feedback = () => {
  var userRoleId:any=sessionStorage.getItem('userRoleId')
  const [loader,setLoader]=useState(false);
  const [feedbackData,setFeedbackData]=useState([]);
  const authToken =
    sessionStorage.getItem("apiToken") === undefined
      ? ""
      : sessionStorage.getItem("apiToken");
  
  const columnDefs:any =   
  [
    { name: "User Name", selector: (row: any) => row.user_name, sortable: true },
    { name: "Phone", selector: (row: any) => row.phone_number, sortable: true },
    { name: "Feedback Category", selector: (row: any) => row.category, sortable: true , filter:true},
    { name: "Email", selector: (row: any) => row.email, sortable: true },
    { name: "Organisation", selector: (row: any) => row.organisation, sortable: true },
    { name: "Feedback", selector: (row: any) => row.feedback, sortable: true, cell: (row: any) => (
        <div
          style={{
            whiteSpace: "normal", // or "pre-line" for preserving newlines
            wordWrap: "break-word", // to allow long words to break
            textAlign: "left", // Align text to the left
          }}
        >
          {row.feedback}
        </div>
      ),},
   /*  {
      name: "Action",
      center:true,
      cell: (row: any) => (
        <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%", // Set width to 100%
        textAlign: "center", // Align content to the center
      }}
    >
          <FaTelegramPlane style={{height:'18px',width:'18px',color:'#0072B2', cursor:'pointer'}} />
        </div>
      ),
    }, */
  ]
  const titleCase = (str: any) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchData = async () => {
   setLoader(true)
    var link = `${baseURL}/feedbacks`;
    await axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((data) => {
        console.log(data);
        setFeedbackData(data.data.data)
      })
      .catch((error) => {
        console.error("Error fetching user data in AnotherFile:", error);
        setLoader(false);
      });
    setLoader(false)
  };

  useEffect(() => {
    fetchData();
  }, []);

  const findRoleDetails = (roleId: any, rolelist: any) => {
    return rolelist.find((role: any) => role.role_id === roleId);
  };

  
  return (
    <>

      {loader ? (
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="titlename">Customer Feedback</div>
          <DataTable
            title={<div className="managementTableTitle">Feedback List</div>}
            columns={columnDefs}
            data={feedbackData}
            pagination
            fixedHeader
            responsive
            fixedHeaderScrollHeight="700px"
            className="managementTable"
            defaultSortFieldId="role_name"
          />



        </>
      )}
    </>
  );
};

export default Feedback;
