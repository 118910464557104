import React, { useEffect, useState } from "react";
import portfoliologo from "../Images/portfoliologo.png";
import { Modal, Button } from "react-bootstrap";
import '../CSS/common.css';


const Terms =({closeModal,openModal}:any)=>{
    return(
        <Modal
        className="policyModal modal-xl"
        centered
        show={openModal}
        onHide={closeModal} 
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <h5 className="card-title">
              <img
                src={portfoliologo}
                style={{ maxHeight: "4vh", maxWidth: "40vw" }}
                alt="Logo"
              />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "700px", textAlign:'justify' }} className="overflow-auto">
          <p style={{fontWeight:700}}>End User Licensing Agreement for Workplace Portfolio Analytics</p>
          <p style={{fontWeight:700}}>BY USING THIS SOFTWARE, YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS LICENSE. IF YOU
DO NOT AGREE TO THE TERMS OF THIS LICENSE, DO NOT USE THE SOFTWARE.</p>
<p><u>Version date: December 7, 2023</u></p>
<p>Please read this Software License Agreement ("License") before using this Software. This License
governs your use of Workplace Portfolio Analytics (“Software”). This License also will govern any
Software upgrades provided by Placid Investments LLC, Innovation Places LLC, or Digital Finance
Transformation LLC (collectively the “Licensor”) that replace or supplement the original unless
accompanied by a separate license in which case the terms of that license will govern. To use this
Software, you must have a properly licensed Apple iPad 9th generation or current model with a minimum
iOS of 15. The Apple iPad is not part of this license agreement.</p>
          <ol style={{padding:0}}>
            <li style={{fontWeight:700}}>
              <p >Introduction :</p> </li>
              <p>
              The Licensor is the owner of Workplace Portfolio Analytics ("Software") which
supports the effective data capture of workspace, workplace, and financial data enabling customers
to assess current and future workspace requirements. The Licensors retain and own all intellectual
property rights associated with the Software including, but not limited to, all trademarks, service
marks, trade names, copyrights, source codes, and any upgrades provided by the Licensors. The
Licensors also retain all rights not expressly granted to you in this License. The Apple iPad and iOS
are registered trademarks of Apple Inc (“Apple”). The Licensors are not responsible for any patches,
dependencies, or changes provided by Apple for their products.
              </p>
            
            <li style={{fontWeight:700}}>
              <p >License Grant :</p> </li>
              <p>
              Subject to the terms and restrictions of this License, the Licensors grant you one
limited, non-exclusive license to use the Software in the United States of America for your business
purposes, provided you comply with this License and all its restrictions and limitations.
                <ol type='a'>
                  <li>You receive no title to or ownership of any copy or of the Software itself. You receive no
rights to the Software other than those specifically granted herein. You will not: (i) modify,
create derivative works from, distribute, publicly display, publicly perform, or sublicense the
Software; (ii) use the Software for any purpose that allows third parties to exploit the
Software; (iii) reverse engineer, decompile, disassemble, or otherwise attempt to derive any
of the Software’s source code; (iv) copy or reproduce or duplicate Software unless
permitted by this License.</li>
                  <li>You may make only one copy of the Software in machine-readable form for backup
purposes only; provided that the backup copy must include all copyright or other
proprietary notices contained on the original. You may not rent, lease, lend, redistribute,
sublicense, assign, transfer, or otherwise copy the Software.</li>
                  <li>Use of this Software requires access to a properly licensed iPad 9th generation or newer
version from Apple. The Licensors do not provide any license for any Apple products as part
of this License.</li>
                </ol>
              </p>
           
            <li style={{fontWeight:700}}>
              <p >Ownership and Intellectual Property :</p>
              </li>
              <p>
                <ol type='a'>
                  <li>
                  You shall not disclose, duplicate, or publish at any time any Proprietary Information
concerning the Software, including, but not limited to, information relating to programs,
formulas, processes, methods, machines, products, data, inventions, source codes,
discoveries, or any proprietary matter. You further agree not to: (i) disassemble, decompile,
or reverse engineer the Software or (ii) duplicate, copy, or reproduce the Software except as
permitted by this License.
                  </li>
                  <li> You agree that breach of this section will cause the Licensors irreparable injury, for which
monetary damages would not provide adequate compensation, and that in addition to any
other remedy, the Licensors will be entitled to injunctive relief against such breach or
threatened breach, without providing actual damage or posting a bond or other security.</li>
                  <li>
                  Notwithstanding the foregoing, you may disclose confidential information as required by
applicable law or proper legal or government authority, in any such instance, you shall
promptly inform the Licensors with notice of such demand or request from any proper legal
or governmental authority, and in no case in less than the amount of time given for
compliance by proper legal or governmental authority. You will cooperate with the
Licensors in any effort to seek a protective order or otherwise to contest such required
disclosure.
                  </li>
                </ol>
              </p>
            

            <li style={{fontWeight:700}}>
              <p >Warranties and Disclaimers :  NO WARRANTIES</p>
            </li>
            <p>
                <ol type='a'>
                    <li>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF THE SOFTWARE IS AT YOUR
SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
ACCURACY, AND EFFORT IS WITH YOU. NO WARRANTIES APPLY. THE SOFTWARE IS
PROVIDED “AS IS” AND “WHERE-IS” AND THE LICENSORS DISCLAIM ALL WARRANTIES,
WHETHER WRITTEN OR ORAL, EXPRESS, OR IMPLIED, OR STATUTORY, INCLUDING BUT NOT
LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE, OR FITNESS FOR A PARTICULAR
PURPOSE.</li>
<li>THE LICENSORS DO NOT WARRANT THAT THE SOFTWARE IS ERROR-FREE, BUG-FREE, OR
WILL OPERATE IN AN UNINTERRUPTED MANNER OR IN COMBINATION WITH OTHER
SOFTWARE AND HARDWARE PRODUCTS. YOU UNDERSTAND AND ACKNOWLEDGE THAT
THE LICENSORS ARE NOT RESPONSIBLE FOR ANY DATA CORRUPTION OR CHANGES TO DATA
USING THIS SOFTWARE.</li>
<li>
THERE IS NO WARRANTY FOR THIRD-PARTY SOFTWARE OR THIRD-PARTY HARDWARE
PROVIDED BY THE LICENSORS OR ALREADY IN USE BY YOU. ALL THIRD-PARTY SOFTWARE OR
THIRD-PARTY HARDWARE, INCLUDING BUT NOT LIMITED TO THE OPERATING
ENVIRONMENT SHALL BE GOVERNED BY THE WARRANTIES OFFERED BY THE APPLICABLE
THIRD PARTY UNDER THE TERMS OF THE AGREEMENT BETWEEN YOU AND SUCH THIRD
PARTY. IN ADDITION, AND WITHOUT LIMITATION, THE LICENSOR DOES NOT WARRANT OR
REPRESENT THAT THE SOFTWARE AS DELIVERED WILL MEET ALL OR ANY OF YOUR BUSINESS
REQUIREMENTS OR ENCOMPASS ALL THE FUNCTIONALITY DESIRABLE BY YOU. THERE IS NO
WARRANTY THAT ANY DEFECTS WILL BE CORRECTED.
</li>
                </ol>
            </p>

            <li style={{fontWeight:700}}>
              <p >Limitation of Liability :</p>
            </li>
            <p>
              <ol type='a'>
                <li> In no event shall the Licensors be liable for incidental, special, indirect, punitive, exemplary,
or consequential damages including, without limitation, any damages relating to lost profits
or loss of business opportunity. Should the software prove defective, you assume the entire
cost of all necessary servicing, repair, or correction.</li>
                <li>
                No matter the nature or type of damages claimed, the Licensor's aggregate liability for all
actions and claims arising out of or related to this agreement shall not exceed the amounts
paid by you for the Software. You acknowledge that the limitation of liabilities and
disclaimers of all warranties contained herein constitute an agreed-upon allocation of risk
between the parties, have been factored into the pricing of the software, and are an
essential element of the license to use this software.
                </li>
              </ol>
            </p>

            <li>
              <p style={{fontWeight:700}}>Termination :</p>
            </li>
            <p>
            The Licensors reserve the right to terminate this License at any time for violation of
the terms of this License. This License terminates automatically without any notice if you fail to
comply with the terms of this License. Upon termination of this License, you shall destroy all copies
of the Software.
            </p>

            <li>
              <p style={{fontWeight:700}}>Governing Law, Class Actions, Arbitration, Jurisdiction, Venue, Jury Waiver :</p>
            </li>
            <p>
             <ol type='a'>
                <li style={{fontWeight:700}}>
                Governing Law
                </li>
                <p>
                    <ol type="i">
                        <li>
                        This License shall be governed by the laws of the State of Connecticut, United
States, without regard to principles of conflict of laws. This License shall not be
governed by any other law or treaty, including but not limited to, the United Nations
Convention on Contracts.
                        </li>
                        <li>
                        GDPR does not apply as the application will not monitor behavior of individuals in
the EU nor is it intended to collect or process personal data of individuals in the EU.
                        </li>
                    </ol>
                </p>
                <li style={{fontWeight:700}}>
                Waiver of Class Actions
                </li><p>
                <ol type='i' style={{fontWeight:700}}>
        <li>
        You agree and covenant not to file any action, suit, complaint, claim, grievance,
demand for arbitration, or other proceeding against the Licensor, either
individually or as a member of a class in any class or collective action, in any court
or other forum with regard to any claim, demand, liability, obligation or matter
arising out of this License or your use of the Software, or otherwise.
        </li>
        <li>
        You waive any right to become, and promise not to voluntarily become, a member
of any class in a case in which any claim or claims are asserted against any of the
Licensor involving any act or event occurring as a result of your use of the
Software or a dispute of any terms of this License. If any claim is brought on your
behalf against the Licensor, or if you learn that you are named as a member of any
class in a case in which any claim or claims are asserted against any of the Licensor
involving the Licensor you agree to provide prompt, written notification of said
claim to the Licensor to the extent permitted by law, to withdraw in writing and
with prejudice from said claim or class; and to forward a copy of your withdrawal
to the Licensor.
        </li>
                </ol>
                </p>

                <li style={{fontWeight:700}}>
                Mediation and Arbitration
                </li><p>
                <ol type='i' style={{fontWeight:700}}>
        <li>
        If a dispute arises out of or relates to this contract or the breach thereof, and if the
dispute cannot be settled through negotiation, the parties agree first to try in
good faith to settle the dispute by mediation administered by the American
Arbitration Association under its Commercial Mediation Procedures before
resorting to arbitration, litigation, or some other dispute resolution procedure.
        </li>
        <li>
        Any controversy or claim arising out of or relating to this contract, or the breach
thereof, shall be settled by arbitration administered by the American Arbitration
Association in accordance with its Commercial Arbitration Rules, and judgment on
the award rendered by the arbitrator(s) may be entered in any court having
jurisdiction thereof.
        </li>
                </ol>
                </p>

                <li style={{fontWeight:700}}>
                Jurisdiction and Venue
                </li><p>
                <ol type='i'>
        <li>
        All suits, proceedings, and other actions relating to, arising out of, or in connection
with this License will be submitted to the exclusive, in personam jurisdiction, of the
state and federal courts located in the State of Connecticut nearest New Haven
County. The venue for all such suits, proceedings, and other actions will be
exclusively in Connecticut. By use of this Software you hereby waive any claims
against or objections to such in personam jurisdiction and venue.
        </li>

                </ol>
                </p> 


                <li style={{fontWeight:700}}>
                Jury Waiver
                </li><p>
                <ol type='i' style={{fontWeight:700}}>
        <li>
        THE PARTIES UNDERSTAND AND AGREE THAT THEY ARE WAIVING THEIR RIGHTS
TO A JURY TRIAL
        </li>

                </ol>
                </p> 
             </ol>
            </p>

            <li style={{fontWeight:700}}>
              <p>Attorney’s Fees :</p>
            </li>
            <p>
              <ol type='a'>
                <li>In the event that you bring litigation or a lawsuit arising out of the use of this Software, and
you do not substantially prevail in the litigation or lawsuit, you agree that Licensor shall be
paid its reasonable attorneys' fees and costs for defending the litigation or lawsuit. In the
event that the Licensor prevails in any litigation or lawsuit initiated to enforce any provision
of this License, or for violation of its terms or the Licensor’s intellectual property rights in
the Software, you agree that the Licensor shall be paid its reasonable attorney's fees and
costs.</li>
              </ol>
            </p>

            <li style={{fontWeight:700}}>
              <p>Miscellaneous :</p>
            </li>
            
            <p>
              <ol type='a'>
                <li style={{fontWeight:700}}>
                Severability
                </li>
                <p>
                    <ol type='i'>
                        <li>
                        If any one or more of the provisions contained herein shall for any reason be held to
be unenforceable in any respect, such unenforceability shall not affect any other
provision of this License, but this License shall then be construed as if such
unenforceable provision or provisions had never been contained herein.
                        </li>
                    </ol>
                </p>
                
                <li style={{fontWeight:700}}>
                No Waiver
                </li>
                <p>
                    <ol type='i'>
                        <li>
                        A waiver of a breach or default under this License shall not be a waiver of any
subsequent breach or default hereunder. Failure of either party to enforce
compliance with any term or condition of this License shall not constitute a waiver
of such term or condition.
                        </li>
                    </ol>
                </p>

                <li style={{fontWeight:700}}>
                Entire Agreement
                </li>
                <p>
                    <ol type='i'>
                        <li>
                        This License constitutes the entire agreement between the parties and supersedes
any prior or contemporaneous oral or written representations with regard to the
subject matter hereof.
                        </li>
                    </ol>
                </p>

                <li style={{fontWeight:700}}>
                Amendments
                </li>
                <p>
                    <ol type='i'>
                        <li>
                        The current Version Date of this License is located at the top of the first page of the
License. Licensor reserves the right, in its sole discretion, to modify or replace this
License at any time, by continuing to access or use the Software after any Version
Date, you agree to be bound by the revised License. If you do not agree to the new
revised License, in whole or in part, do not use the software.
                        </li>
                    </ol>
                </p>
              </ol>
            </p>
            
          </ol>
        </Modal.Body>
        <Modal.Footer className="mt-auto d-flex justify-content-center">
         <Button className="submit" onClick={closeModal}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    )

}

export default Terms;