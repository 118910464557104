import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
//import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import DataTable from 'react-data-table-component';
import Switch from "react-switch";
import { findUserAccessRank, baseURL } from './Apis';
import '../CSS/common.css'
import Accesserrormodal from './Accesserrormodal';
import xlsx from "json-as-xlsx";
import moment from 'moment';

function Sitelist() {
  var userName:any=sessionStorage.getItem('username')===undefined?'':sessionStorage.getItem('username');
  var subscribedFlag:any=sessionStorage.getItem('subscribedFlag')=== undefined ?'false':sessionStorage.getItem('subscribedFlag');
  var userRank:any=sessionStorage.getItem('userAccessRank')=== undefined ?'0':sessionStorage.getItem('userAccessRank');
  var permissionIDS:any=sessionStorage.getItem('permissionIDS')=== undefined || sessionStorage.getItem('permissionIDS')=== null ?'1,2,3,4':sessionStorage.getItem('permissionIDS');
  const authToken = sessionStorage.getItem('apiToken') === undefined ? '' : sessionStorage.getItem('apiToken')
  var userEmail = sessionStorage.getItem('useremail') === undefined ? '' : sessionStorage.getItem('useremail')
  var userRoleId:any=sessionStorage.getItem('userRoleId')==undefined?'':sessionStorage.getItem('userRoleId');
  const [pending, setPending] = useState(true);
  const [modalType,setModalType]=useState('');
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [rowData,setRowData ]= useState([
    
    // Add more data rows as needed
  ]);

  const conditionalRowStyles = [
    {
      when: (row:any) => row.is_deleted === 1,
      style: {
        color: '#a7adba',
      },
    },
  ];

  useEffect(() => {
    var link:any; 
    if(userRoleId=='1'){
      link= baseURL + 'getAllLeaseData';
    }else{
    link= baseURL + 'getAllLeaseDataa';
    }
    axios.get(link, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        const timeout = setTimeout(() => {
        setRowData(response.data.data)
        setPending(false)
        },2000)
        return () => clearTimeout(timeout);
        
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
      if(userRank=='2'){
        setModalType('2');
        handleShow();
      }
      if(userRank=='3'){
        setModalType('3');
        handleShow();
      }
      if(userRank=='4'){
        setModalType('3');
        handleShow();
      }
      
    },[])
  
   



  const updateData=(row:any)=>{
    console.log(row)
    sessionStorage.setItem('IdForUpdate',row.lease_id)
    window.location.href = '../home/addSite/singleSite';
    //navigate('../home/addSite/singleSite')
  } 
  
  const softDelete=async(data:any)=>{
    var link = baseURL + 'deleteLeaseData/' + data.lease_id;
      try {
        setPending(true)
        const checkUsername = await axios.put(link, data,{
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (checkUsername.data.status === 200) {
          if(userRoleId=='1'){
            link= baseURL + 'getAllLeaseData';
          }else{
          link= baseURL + 'getAllLeaseDataa';
          }
    axios.get(link, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        const timeout = setTimeout(() => {
        setRowData(response.data.data)
        setPending(false)
        },2000)
        return () => clearTimeout(timeout);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
        }
      }
      catch (error) {
        // Handle errors here
        console.error('An error occurred:', error);
        // You can set an error state or show an error message to the user
      }
    
    
  }
  const columnDefs  = [ 
    {name: "Lease ID",selector: (row:any) => row.lease_id},
    {name: "Site ID",selector: (row:any) => row.site_id},
    {name: "Document Type",selector: (row:any) => row.document_type},
    {name: "Building",selector: (row:any) => row.building},
    {name: "Address",selector: (row:any) => row.address},
    {name: "Floor",selector: (row:any) => row.floor},
    {name: "City",selector: (row:any) => row.city},
    {name: "State/District/Province",selector: (row:any) => row.state_district_province},
    {name: "Postal Code",selector: (row:any) => row.postal_code},
    {name: "Country Name",selector: (row:any) => row.country_name},
    {name: "Country Code",selector: (row:any) => row.country_code},
    {name: "Region",selector: (row:any) => row.region},
    {name: "Sub Region",selector: (row:any) => row.sub_region},
    {name: "Latitude",selector: (row:any) => row.latitude},
    {name: "Longitude",selector: (row:any) => row.longitude},
    {name:"Additional Facilities Cost",selector: (row:any) => row.additional_facilities_cost},
  {name: "Annualized Base Rent",selector: (row:any) => row.annualized_base_rent},
  {name: "Business Unit",selector: (row:any) => row.business_unit},
  {name: "Capacity Seats",selector: (row:any) => row.capacity_seats},
  {name: "Client Position",selector: (row:any) => row.client_position},
  {name: "Cost Employee",selector: (row:any) => row.cost_employee},
  {name: "Cost Occupant",selector: (row:any) => row.cost_occupant},
  {name: "Cost Seat",selector: (row:any) => row.cost_seat},
  {name: "Cost SF",selector: (row:any) => row.cost_sf},
  {name: "Cost SF 1",selector: (row:any) => row.cost_sf_1},
  {name: "Critical Decision Date",selector: (row:any) => row.critical_decision_date},
  {name: "Facilities Cost Fully Loaded",selector: (row:any) => row.facilities_cost_fully_loaded},  
  {name: "Lease Commencement Date",selector: (row:any) => row.lease_commencement_date},
  {name: "Lease Expiration Date",selector: (row:any) => row.lease_expiration_date},
  {name: "Lease Expiration Year",selector: (row:any) => row.lease_expiration_year},
  {name: "Lease Type",selector: (row:any) => row.lease_type},
  {name: "Leased Owned",selector: (row:any) => row.leased_owned},
  {name: "Legacy Company",selector: (row:any) => row.legacy_company},
  {name: "Opex Sga",selector: (row:any) => row.opex_sga},
  {name: "Opex Total Revenues",selector: (row:any) => row.opex_total_revenues},
  {name: "Parking Capacity",selector: (row:any) => row.parking_capacity},
  {name: "Rentable SF",selector: (row:any) => row.rentable_sf},
  {name: "Revenues",selector: (row:any) => row.revenues},
  {name: "Rmo Months",selector: (row:any) => row.rmo_months},
  {name: "SF Employee",selector: (row:any) => row.sf_employee},
  {name: "SF Occupant",selector: (row:any) => row.sf_occupant},
  {name: "SF Seat",selector: (row:any) => row.sf_seat},
  {name: "SGA",selector: (row:any) => row.sga},
  {name: "Space Use",selector: (row:any) => row.space_use},
  {name: "Status",selector: (row:any) => row.status},
  {name: "Strategy Head Count Adjustment in Year of change",selector: (row:any) => row.strategy_head_count_adjustment_in_year_of_change},
  {name: "strategy Notes",selector: (row:any) => row.strategy_notes},
  {name: "strategy Occupancy Adjustment In Year Of Change",selector: (row:any) => row.strategy_occupancy_adjustment_in_year_of_change},
  {name: "strategy Optional Committed OR NA",selector: (row:any) => row.strategy_optional_committed_or_na},
  {name: "strategy Quarter of Change ",selector: (row:any) => row.strategy_quarter_of_change},
  {name: "strategy Real Estate Initiative",selector: (row:any) => row.strategy_real_estate_initiative},
  {name: "strategy Reduction Addition OR AS_IS",selector: (row:any) => row.strategy_reduction_addition_or_as_is},
  {name: "strategy_seat_count_adjustment_in_year_of_change",selector: (row:any) => row.strategy_seat_count_adjustment_in_year_of_change},
  {name: "strategy_square_foot_adjustment_in_year_of_change",selector: (row:any) => row.strategy_square_foot_adjustment_in_year_of_change},
  {name: "strategy_top_opportunities",selector: (row:any) => row.strategy_top_opportunities},
  {name: "strategy_type",selector: (row:any) => row.strategy_type},
  {name: "strategy_year_of_change_yyyy",selector: (row:any) => row.strategy_year_of_change_yyyy},
  {name: "surplus_deficit_seat_capacity",selector: (row:any) => row.surplus_deficit_seat_capacity},
  {name: "total_employee_hc",selector: (row:any) => row.total_employee_hc},
  {name: "total_occupancy_hc",selector: (row:any) => row.total_occupancy_hc},
  {name: "vacant",selector: (row:any) => row.vacant},
  {name: "verified_yn",selector: (row:any) => row.verified_yn},
  {name: "Created by",selector: (row:any) => row.user_name},

  
  {name: "Actions", left:true ,cell: (row:any) => ( 
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {userRank=='2'?<>NA</>:
      <>
      {permissionIDS.includes(4)?<>
    <Switch
    onChange={() => softDelete(row)}
    checked={row.is_deleted===0?true:false}
    height={20} // Adjust the height of the switch
    width={40} // Adjust the width of the switch
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.1)"
    uncheckedIcon={false}
    checkedIcon={false}
  />&emsp;</>:<></>}
      {row.is_deleted===1 || userRoleId=='1'?<></>:<i className="fa fa-pencil fa-lg" style={{color:"#0072B2",cursor:"pointer"}} aria-hidden="true" onClick={()=>updateData(row)}></i>}
      </>}
      </div>
  ),}
];

let exportData:any = [
  {
    sheet: "Lease Data",
    columns: [
      {label: "Lease Id",value: (row:any) => row.lease_id},
    {label: "Site Id",value: (row:any) => row.site_id},
    {label: "Document Type",value: (row:any) => row.document_type},
    {label: "Building",value: (row:any) => row.building},
    {label: "Address",value: (row:any) => row.address},
    {label: "Floor",value: (row:any) => row.floor},
    {label: "City",value: (row:any) => row.city},
    {label: "State/District/Province",value: (row:any) => row.state_district_province},
    {label: "Postal Code",value: (row:any) => row.postal_code},
    {label: "Country label",value: (row:any) => row.country_label},
    {label: "Country Code",value: (row:any) => row.country_code},
    {label: "Region",value: (row:any) => row.region},
    {label: "Sub Region",value: (row:any) => row.sub_region},
    {label: "Latitude",value: (row:any) => row.latitude},
    {label: "Longitude",value: (row:any) => row.longitude},
    {label:"Additional Facilities Cost",value: (row:any) => row.additional_facilities_cost},
  {label: "Annualized Base Rent",value: (row:any) => row.annualized_base_rent},
  {label: "Business Unit",value: (row:any) => row.business_unit},
  {label: "Capacity Seats",value: (row:any) => row.capacity_seats},
  {label: "Client Position",value: (row:any) => row.client_position},
  {label: "Cost Employee",value: (row:any) => row.cost_employee},
  {label: "Cost Occupant",value: (row:any) => row.cost_occupant},
  {label: "Cost Seat",value: (row:any) => row.cost_seat},
  {label: "Cost SF",value: (row:any) => row.cost_sf},
  {label: "Cost SF 1",value: (row:any) => row.cost_sf_1},
  {label: "Critical Decision Date",value: (row:any) => row.critical_decision_date},
  {label: "Facilities Cost Fully Loaded",value: (row:any) => row.facilities_cost_fully_loaded},  
  {label: "Lease Commencement Date",value: (row:any) => row.lease_commencement_date},
  {label: "Lease Expiration Date",value: (row:any) => row.lease_expiration_date},
  {label: "Lease Expiration Year",value: (row:any) => row.lease_expiration_year},
  {label: "Lease Type",value: (row:any) => row.lease_type},
  {label: "Leased Owned",value: (row:any) => row.leased_owned},
  {label: "Legacy Company",value: (row:any) => row.legacy_company},
  {label: "Opex Sga",value: (row:any) => row.opex_sga},
  {label: "Opex Total Revenues",value: (row:any) => row.opex_total_revenues},
  {label: "Parking Capacity",value: (row:any) => row.parking_capacity},
  {label: "Rentable SF",value: (row:any) => row.rentable_sf},
  {label: "Revenues",value: (row:any) => row.revenues},
  {label: "Rmo Months",value: (row:any) => row.rmo_months},
  {label: "SF Employee",value: (row:any) => row.sf_employee},
  {label: "SF Occupant",value: (row:any) => row.sf_occupant},
  {label: "SF Seat",value: (row:any) => row.sf_seat},
  {label: "SGA",value: (row:any) => row.sga},
  {label: "Space Use",value: (row:any) => row.space_use},
  {label: "Status",value: (row:any) => row.status},
  {label: "Strategy Head Count Adjustment in Year of change",value: (row:any) => row.strategy_head_count_adjustment_in_year_of_change},
  {label: "strategy Notes",value: (row:any) => row.strategy_notes},
  {label: "strategy Occupancy Adjustment In Year Of Change",value: (row:any) => row.strategy_occupancy_adjustment_in_year_of_change},
  {label: "strategy Optional Committed OR NA",value: (row:any) => row.strategy_optional_committed_or_na},
  {label: "strategy Quarter of Change ",value: (row:any) => row.strategy_quarter_of_change},
  {label: "strategy Real Estate Initiative",value: (row:any) => row.strategy_real_estate_initiative},
  {label: "strategy Reduction Addition OR AS_IS",value: (row:any) => row.strategy_reduction_addition_or_as_is},
  {label: "strategy_seat_count_adjustment_in_year_of_change",value: (row:any) => row.strategy_seat_count_adjustment_in_year_of_change},
  {label: "strategy_square_foot_adjustment_in_year_of_change",value: (row:any) => row.strategy_square_foot_adjustment_in_year_of_change},
  {label: "strategy_top_opportunities",value: (row:any) => row.strategy_top_opportunities},
  {label: "strategy_type",value: (row:any) => row.strategy_type},
  {label: "strategy_year_of_change_yyyy",value: (row:any) => row.strategy_year_of_change_yyyy},
  {label: "surplus_deficit_seat_capacity",value: (row:any) => row.surplus_deficit_seat_capacity},
  {label: "total_employee_hc",value: (row:any) => row.total_employee_hc},
  {label: "total_occupancy_hc",value: (row:any) => row.total_occupancy_hc},
  {label: "vacant",value: (row:any) => row.vacant},
  {label: "verified_yn",value: (row:any) => row.verified_yn},
  {label: "Created by",value: (row:any) => row.user_name}
    ],
    content: rowData
  }]

let settings:any = {
    fileName: userName+' Data Export '+moment().format('YYYY-MM-DD'), // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: { }, // Style options from https://docs.sheetjs.com/docs/api/write-options
    
  }

const navigateSingle =()=>{
  if(userRank=='4')
  {
    setModalType('4');
        handleShow();
  }
  else{navigate('/home/addSite/singleSite')}
}

const navigateBulk =()=>{
  if(userRank=='4')
  {
    setModalType('4');
    handleShow();
  }else{
  navigate('/home/addSite/bulkSites')}
}

  return (
    <>
       
    
   
      {pending?<div id="loader-container">
            <div id="loader">
              <RotatingLines
                strokeColor="#0072B2"
                strokeWidth="5"
                animationDuration="0.75"
                width="100"
                visible={true}
              />
            </div>
          </div>:
          <>
    <div className="titlename">
    Site List
    </div>
    <div className='' style={{}}>
    <div className="col-12" style={{ height: 400 }}>
    <div className="row mt-5 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{flex:"1",textAlign:"left"}}> 
      
      </div>
      
      {userRank=='2' || userRoleId=='1'?
      <><button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} onClick={(e:any)=>{xlsx(exportData, settings)}} className="btn btn-block col-1 rounded-pill mx-2">
      Export
    </button></>:
      <div style={{ flex: "1", textAlign: "right" }}>
      <button type="button" style={{ backgroundColor: '#0DAF4D', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} onClick={(e:any)=>navigateSingle()} className="btn btn-block rounded-pill mx-2">
                  Add Site
                </button>
                <button type="button" style={{ backgroundColor: '#0DAF4D', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} 
                onClick={(e:any)=>{subscribedFlag=='true'?navigateBulk():console.log('')}} className={`btn btn-block rounded-pill ml-2 ${subscribedFlag=='true'?'':'disabledButton'}`}>
                  Bulk Upload
                </button>
                <button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} onClick={(e:any)=>{xlsx(exportData, settings)}} className="btn btn-block rounded-pill mx-2">
                  Export
                </button>
              </div>
      }
              
    </div>
    <DataTable
            columns={columnDefs}
            data={rowData}
            pagination
            fixedHeader
            responsive
            conditionalRowStyles={conditionalRowStyles}
            fixedHeaderScrollHeight="700px"
            className='dataTable'
            progressPending={pending}
			      progressComponent={<div id="loader-container">
            <div id="loader">
              <RotatingLines
                strokeColor="#0072B2"
                strokeWidth="5"
                animationDuration="0.75"
                width="100"
                visible={true}
              />
            </div>
          </div>}
        />
    </div>
    </div>
    <Accesserrormodal closeModal={handleClose} openModal={showModal} modalType={modalType} />
    </> }
    </>
  );
}

export default Sitelist;
